import { ReportAction, ReportType, IReportState } from "../types/reportTypeV2";

const initialState = {
  data: null,
  dataDetail: null,
  dataExport: null,
  isLoadingDDl: false,
  isLoading: false,
  isLoadingDetail: false,
  isLoadingExport: false,
  error: null,
};

export default (
  state: IReportState = initialState,
  { type, payload }: ReportAction
) => {
  switch (type) {
    case ReportType.REPORT_PENDING:
      return { ...state, ...payload, error: null };
    case ReportType.REPORT_SUCCESS:
      return { ...state, ...payload };
    case ReportType.REPORT_ERROR:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
};
