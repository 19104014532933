import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Banner from "../../componentv2/atoms/Banner";
import { Input } from "../../componentv2/atoms/Input";
import { Button } from "../../componentv2/atoms/NewButton";
import AuthLayout2 from "../../containers/AuthLayout2";
import useAuth from "../../redux/hooks/authHooks";
import { IAuthData } from "../../redux/types/authTypes";
import { setCookie } from "../../utils/cookie";
import { encodeGetAccount } from "../../utils/utility";

const Component: React.FC = () => {
    const { initialization, login } = useAuth();
    const history = useHistory();
    const [bannerAction, setBannerAction] = useState<any>({
        type: "success",
        message: "",
        open: false,
    });
    const [values, setValues] = useState<any>({
        email: "",
        password: "",
    });

    const [keyPlatform, setKeyPlatform] = useState<string>("")

    useEffect(() => {
        handleInit();
    }, []);

    const handleInit = () => {
        const body: IAuthData = {
            Platform: "qoin-keycloak",
            Secret: "DHGOO4PGDHD4SLAMAZHWVC"
            // Platform: "admin-qoinpay",
            // Secret: "2EZVPZFKBVNHDSSJKHRG7X",
        };

        initialization(body, (res) => {
            if (res?.StatusCode === 200) {
                localStorage.setItem("platform-key", res.Data.Platformkey);
                // setCookie("platform-key", res?.Data?.Platformkey);
                // setKeyPlatform(res?.Data?.Platformkey)
            }

        });
    };

    const handleChangeForm = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        });
    };



    const handleLogin = () => {
        const body = {
            Password: values.password,
            Email: values.email,
            InstallId: "This is Install ID",
            DeviceId: "This is Device ID",
            DeviceType: "web-browser",
            // Type: "text",
            DeviceBrand: "Lenovo",
            DeviceModel: "PC",
            Location: "Pulo Gadung",
            IP: "172.2.2.1",
        };

        const bodyData = new FormData()
        bodyData.append("Password", values.password)
        bodyData.append("Email", values.email)
        bodyData.append("Source", "web")

        const headers = {
            Platformkey: keyPlatform,
            // "App-Id": "858ec9351746032bb8f3435c9e0b806c2a2facd89cd3835b6259572e771a0f70439fc8445d7e69b3b09b6a6e987746279fb0155549914f519c69c15335b99f19b0b6c965475fbf78154c21b4fa10640c4ccfdbaa6b439f4ee63a126ee2e37aeb6261b9cc3a748a3e28c18b333bc9caec1f3c3438eb2f2b639d372bd162ef0a14c810c274533524c70c59a04dc2b3286b3a43860791d180e6651c6ef1fcd255b5eda9f4ab2b808406ab9264ee5a3839829f59d2619535c92cc41f6882e20ff8b796ea7371cb9bd470d1aecfe4d7c860b6d937f2aa8d47024c332ba6c7082c3cf98227f557763c1baee4632efa09094049c7abaa739a55cf769164b93ab71da1057fa111beb88a5a0e19f0b5cd9ef82f00f5c52320d3006a2969a3dd7272ecbda4f2a0fd3635f8f07e9b7517b8229b9850a8504c16463d70ada861b7b321c631fd87da43cc6956ff37b403bc6e1d592bf2767506a6e32aa455ae70f87208926b71e8a6b809cd10ffa6e5ead48b1fdf06813a525802551c1fd249741f09d1197fa318e05e0d5f090f90ddc7852d99d2d74b617db8db6ddc4522d74f7d43503ced12131f6d37b49f27e17f5b254b8c1fe9aed5ef80074d95fb4069e81f83c20bdfb79f9527fbc385f36bb51951be23481e30b96bc3c624dd39d45e3afd3b664156f586a61ba9ea709e1c70e5d4a4ca1da00e20f0e7d1da47b1283b4ae758cf59c143ef79ff74ce868fe21995a95a968808832a5d49895b49d655e93faf44"
            // "App-Id": "ad24e67215ade14c4d91bb96952b220f7e3bb12a433c504d5f4f2ace1b71f7fc09b7572b7d2e4b95a6cce0e107b4a6938a4c3dc8e8a09cfcb3bf8df829445a87b03831393de130f3da8a5ad77a2f96e46442dc67d09329145f280fa4065b4ab08466e5a08c16526bb48a4de16621e2a62beae7cd19ba1e3b6c9fb6cc1ad9dfed37d6bf586674c4eb78b0ea452c286439378b7dc9b53e79d1ff272b16ddca5bf8a7a6ee58994e854ef23e6b3506937f5eea66bb5cd5cae4c785c7dfcd020495f4c93fcac9ed1293467dea29092baf268fe19d925b8e4e5603e631f6afbb47ce75a746752dfd8c88f76677b33ab25fe9942b78dbeacc5fef7ec884ef72bb194f559bd13dc2259ce79d32142aed4604faafd9bfc2a53af0bc244bad0eb760dd50b27396acac0f6e14f947db9cf6da8911adc4fbb8fdf8f91d80c7e8222fd33fb80982e81f86b07c4ee07a84ad961e6fa55e8c2a0b5a95094f9265be0eb5bcde1e384cd9522f7ad7de436f0a3b3f72d7af370a75c571f8a2f86e0712a5345f01021cc336deadb0bc826e8f91a5e00b49b09ae4087f7394f36a0cfa2b8389464c2d0846c5ea810de7b56de38504910b9f0e9884bb2d791345f071e94a4952ab113c17b51a64c287912032eba83c4cb4d9b600df93e5164f108480d806fb97536c012bb17cb39f05bf090ef100c1e556d8f1125d8b2723baa3c2c0ba2e254354f00a23ad2496de850e4195be4250531b88e8d446c2a8053be5d83e9b801204" //dev
            // "App-id": "52c24978d5f3a15e0f1b80795778db4c0036e6fb51c731f0f161eaeda0e27fb1a6245c9b8f5b85fc910bfdb689917104fa2e7152c35fbcfaa05aecac97724f8ff4f74f6a38c11a04503adbad3ffafbb0880ae01bac59c64fee87c94ecf5c5d5d851b913336ab0d45e91827987be285a6e3135d48ae65d9cddc1be507c4f461fccf1f6bb037242e6815f9ddd16877d739552117f3130086ecac486d9860e43726e5d7ff79bd7ffd488886bfa9b31735b3871aa4bf3be3b69f757d28c53820773dea12ebd789089595426c8812128b367f6d58a8a0a12b72d01fbfddc59c70e902bdd12403b75cd4634647c58f7ab490c788d8c36a7207f48e0335fa861957ff557bf1d5a72b328faac56cf8352ac237c96c34f3b663adbf65ae0bd9fa0c74a659d4576d6638d1110560e698a7361fab4f9d620c4177a25f904d22f988bdb4455cd2f07f1380d3211f614e118f5d763e3a32314fd87b26b0888d1f55182d47814e8ce049b8603aa4b8ef20aeda429b3f65ef24f8cc8ea72518c9a67e47b7ff9b15e86a22cb8875586e0f4b25a50d9d503cb566e136419394990e87373f4f9d26d00b1c1318f100a56f2497c9dbc3b4b74c22ad0b948fe2c0cb36767a98ecc7540f1aa4b02fc4bee5848b41a850041c8362ccbc5619dfd3d2e3c662db1ac8510a6fa273acbe7216df84cc7ee97161f33c506be3b891c911b1d64705e995f7a5423c69480420acc237433fb4e10e926e4cd99180e64b1b203e80c6b91db2" //staging
        }

        login(body, headers, (res) => {

            if (res?.StatusCode === 200) {
                localStorage.setItem(
                    "profile",
                    JSON.stringify((res?.Data))
                );
                localStorage.setItem("token-wallet-webadmin", res?.Data?.Token);
                // setCookie("token-wallet-webadmin", res?.Data?.Token, true);
                setBannerAction({
                    open: true,
                    type: "success",
                    message: res?.message,
                });
                setTimeout(() => {
                    window.location.replace("/");
                }, 200);
            } else {
                setBannerAction({
                    open: true,
                    type: "error",
                    message: res?.message,
                });
            }

            setTimeout(() => {
                setBannerAction({
                    open: false,
                    type: "",
                    message: "",
                });
            }, 3000);
        });
    };

    return (
        <AuthLayout2>
            <div className="login_page w-100">
                <div className="font-weight-bolder h4 text-dark mb-3">Sign In</div>
                {bannerAction.open && (
                    <div className="my-2">
                        <Banner
                            bannerType={bannerAction?.type}
                            message={bannerAction?.message}
                            onAction={() => setBannerAction({ ...bannerAction, open: false })}
                        />
                    </div>
                )}
                <div>
                    <div className="form_login">
                        <label htmlFor="">Email</label>
                        <Input
                            name="email"
                            value={values.email}
                            placeholder="Input Email"
                            max={40}
                            onChange={handleChangeForm}
                        // autoComplete="new-password"
                        />
                    </div>
                    <div className="form_login">
                        <label htmlFor="">Password</label>
                        <Input
                            name="password"
                            type="password"
                            value={values.password}
                            placeholder="Input Password"
                            max={20}
                            onChange={handleChangeForm}
                            iconPosition="right"
                            iconPassword
                        // autoComplete="new-password"
                        />
                    </div>
                    <div className="d-flex justify-content-between align-items-center my-3">
                        <div className="auth__checkbox">
                            <input
                                type="checkbox"
                                className="d-none auth__checkbox-input"
                                id="remember"
                            />
                            <label
                                htmlFor="remember"
                                className="d-flex align-items-center auth__checkbox-label"
                            >
                                <span className="auth__checkbox-custom" />
                                <div className="auth__checkbox-text">Remember me</div>
                            </label>
                        </div>

                        <div
                            className="auth__link cursor-pointer "
                            onClick={() => history.push("/forgot-password")}
                        >
                            Forgot Password?
                        </div>
                    </div>
                    <div className="form_login mt-4">
                        <Button
                            typeBtn="primary"
                            className="w-100 "
                            onAction={handleLogin}
                            disabled={!values.email || !values.password}
                        >
                            Sign In
                        </Button>
                    </div>
                </div>
            </div>
        </AuthLayout2>
    );
};

export default Component;
