import { PermAction, PermType, IPermState } from "../types/permissionTypes";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

export default (
  state: IPermState = initialState,
  { type, payload }: PermAction
) => {
  switch (type) {
    case PermType.PERM_PENDING:
      return { ...state, ...payload, error: null, isLoading: true };
    case PermType.PERM_SUCCESS:
      return { ...state, ...payload, isLoading: false };
    case PermType.PERM_ERROR:
      return { ...state, ...payload, isLoading: false };

    default:
      return state;
  }
};
