import { AxiosError } from "axios";
import { IMiddleResponse } from "./middlewareTypes";

export enum TrxListType {
  TRXLIST_PENDING = "TRXLIST_PENDING",
  TRXLIST_SUCCESS = "TRXLIST_SUCCESS",
  TRXLIST_ERROR = "TRXLIST_ERROR",
}

export interface IParamTrxList {
  page: number;
  pageSize: number;
  maxPage?: number;
  status?: number;
  dateTo?: string;
  dateFrom?: string;
  token?: string;
  orderType?: string;
  trxReceipt?: string;
  hash?: string;
  refNo?: string;
  orderId?: string;
  phone?: string;
  trxType?: string;
  memberId?: string;
}

export interface ITrxListDataMaster {
  trxId: number;
  trxClientId: number;
  trxMemberId: number;
  trxType: number;
  trxOrderType: string;
  trxPaymentType: string;
  trxDate: string;
  trxReceipt: string;
  trxUser: string;
  trxStatus: string;
  trxPhone: string;
  trxPayload: string;
  pGroup: string;
  pName: string;
  mArea: string;
}

export interface ITrxListResponse {
  code: number;
  status: string;
  message: string;
  data: any;
  paginate: {
    page: number;
    total_page: number;
    total_record: number;
    limit: number;
  };
}

export interface ITrxListResponseMaster {
  Code: number;
  Status: string;
  Message: string;
  Data: ITrxListDataMaster;
  Paginate: {
    CurrentPage: number;
    NextPage: number;
    PrevPage: number;
    TotalPage: number;
    TotalRow: number;
  };
}

export interface IMiddlewareBodyTrxListIn {
  ClientId: number;
  Subject: "transaction";
  SubjectType: "transaction";
  SubjectIdentifier: "transaction";
  Action: "gethistoryall";
  ResourceType: "data";
  Resource: "data";
  ResourceId: string;
  UserId: string;
  Date: string;
  Data: IParamTrxList;
}

export interface IMiddlewareBodyTrxListOut {
  TicketId: string;
  Subject: "transaction";
  SubjectType: "transaction";
  SubjectIdentifier: "transaction";
}

export interface IMiddlewareResponseTrxListOut {
  ClientId: number;
  Subject: "transaction";
  SubjectType: "transaction";
  SubjectIdentifier: "transaction";
  Action: "gethistoryall";
  ResourceType: string;
  Resource: string;
  ResourceId: string;
  UserId: string;
  Date: string;
  Result: ITrxListResponseMaster | any;
  Status?: string;
  StatusCode?: number;
}

interface GetTrxListAction {
  type: TrxListType.TRXLIST_SUCCESS;
  payload: {
    data: IMiddlewareResponseTrxListOut | IMiddleResponse | null;
  };
}

interface SetLoadingTrxListAction {
  type: TrxListType.TRXLIST_PENDING;
  payload?: {
    data: any;
  };
}

interface SetErrorTrxListAction {
  type: TrxListType.TRXLIST_ERROR;
  payload: {
    error: AxiosError;
  };
}

export interface ITrxListState {
  data: IMiddlewareResponseTrxListOut | IMiddleResponse;
  isLoading: boolean;
  error: AxiosError;
  dataDetail: any;
  isLoadingDetail: boolean;
  dataExport: any;
  isLoadingExport: boolean;
  isLoadingDDl: boolean;
}

export type TrxListAction =
  | GetTrxListAction
  | SetLoadingTrxListAction
  | SetErrorTrxListAction;
