import React from "react";
import { IInputDate } from "./interface";
import DatePicker from "react-datepicker";

import "./input.scss";
const Component: React.FC<IInputDate> = ({
  startDate,
  onChange,
  placeholder,
  dateNow,
}) => {
  return (
    <>
      <div className="w-100">
        <DatePicker
          className="date-pick"
          selected={startDate}
          dateFormat="dd/MM/yyyy"
          minDate={dateNow ? new Date() : null}
          onChange={onChange}
          onKeyDown={(e) => {
            e.preventDefault();
          }}
          placeholderText={placeholder || ""}
        />
      </div>
    </>
  );
};

export default Component;
