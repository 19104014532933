import { UserAction, UserType, IUserState } from "../types/userTypes";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

export default (
  state: IUserState = initialState,
  { type, payload }: UserAction
) => {
  switch (type) {
    case UserType.USER_LIST_PENDING:
      return { ...state, ...payload, error: null, isLoading: true };
    case UserType.USER_LIST_SUCCESS:
      return { ...state, ...payload, isLoading: false };
    case UserType.USER_LIST_ERROR:
      return { ...state, ...payload, isLoading: false };

    default:
      return state;
  }
};
