import { AxiosError } from "axios";

export enum ProfileType {
  PROFILE_PENDING = "PROFILE_PENDING",
  PROFILE_SUCCESS = "PROFILE_SUCCESS",
  PROFILE_ERROR = "PROFILE_ERROR",
}

export interface IProfileData {
  Fullname?: string;
  NIK?: number | string;
  KK?: number | string;
  Username?: string;
  ProfileImage?: string;
}

export interface IProfileResponse {
  Code: number;
  StatusCode: number;
  Message: string;
  Data: IProfileData[] & IProfileData;
}

interface GetProfileAction {
  type: ProfileType.PROFILE_SUCCESS;
  payload: {
    data: IProfileResponse;
  };
}

interface SetLoadingProfileAction {
  type: ProfileType.PROFILE_PENDING;
  payload?: any;
}

interface SetErrorProfileAction {
  type: ProfileType.PROFILE_ERROR;
  payload: {
    error: AxiosError;
  };
}

export interface IProfileState {
  data: IProfileData[] & IProfileData;
  isLoading: boolean;
  error: AxiosError;
}

export type ProfileAction =
  | GetProfileAction
  | SetLoadingProfileAction
  | SetErrorProfileAction;
