import { combineReducers } from "redux";

import userReducer from "./userReducer";
import roleReducer from "./roleReducer";
import permReducer from "./permissionReducer";
import profileReducer from "./profileReducer";
import authReducers from "./authReducers";
import reportReducer from "./reportReducersV2";
import errorReducer from "./errorReducers";
import adjReducers from "./adjReducers";
import middlewareReducers from "./middlewareReducers";
import ticketReducersv2 from "./ticketReducersv2";
import refundReducer from "./refundReducer";
import trxListReducer from "./transactionListReducer";
import additionalFeeReducer from "./additionalReducer";

export const rootReducer = combineReducers({
  users: userReducer,
  roles: roleReducer,
  permission: permReducer,
  profile: profileReducer,
  auth: authReducers,
  report: reportReducer,
  error: errorReducer,
  adjudicator: adjReducers,
  middleware: middlewareReducers,
  ticket: ticketReducersv2,
  refund: refundReducer,
  trxList: trxListReducer,
  additionalFee: additionalFeeReducer,
});
