import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Banner from "../../componentv2/atoms/Banner";
import { Input } from "../../componentv2/atoms/Input";
import { Button } from "../../componentv2/atoms/NewButton";
import AuthLayout2 from "../../containers/AuthLayout2";
import useAuth from "../../redux/hooks/authHooks";
import { IAuthData } from "../../redux/types/authTypes";
import { encodeGetAccount } from "../../utils/utility";

const Component: React.FC = () => {
    const { forgotPassword } = useAuth()
    const history = useHistory()
    const [bannerAction, setBannerAction] = useState<any>({
        type: "",
        message: "",
        open: false,
    })
    const [values, setValues] = useState<any>({
        email: "",
    })

    const [errorText, setErrorText] = useState<any>({})

    const handleChangeForm = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })

    }
    useEffect(() => {
        validationEmail()
    }, [values.email])


    const validationEmail = () => {
        let errors: any = {};
        const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; //regex email

        if (!values.email) {
            errors.email = ""
        } else if (!regexEmail.test(values.email)) {
            errors.email = "Email is not valid (example@email.co)";
        } else {
            errors.email = ""
        }

        setErrorText(errors);

        return Object.keys(errors).length === 0;
    }

    const handleLogin = () => {
        const body = {

            Email: values.email,

        };

        forgotPassword(body, (res) => {
            if (res?.StatusCode === 200) {
                setBannerAction({
                    open: true,
                    type: "success",
                    message: res?.Message
                })
            } else {
                setBannerAction({
                    open: true,
                    type: "error",
                    message: res?.Message
                })
            }
        })


    }

    return (
        <AuthLayout2>
            <div className="login_page w-100">
                <div className="font-weight-bolder h4 text-dark mb-3">Reset Password</div>
                {bannerAction.open && (
                    <div className="my-2">
                        <Banner bannerType={bannerAction?.type} message={bannerAction?.message} onAction={() => setBannerAction({ ...bannerAction, open: false })} />
                    </div>
                )}
                <div>
                    <div className="form_login">
                        <label htmlFor="">Email</label>
                        <Input name="email" value={values.email} placeholder="Input valid email" onChange={handleChangeForm} validText={errorText?.email} error={errorText?.email} />
                    </div>
                    <div
                        className="auth__text auth__text-medium my-3"

                    >
                        Remember password ? Back to{" "}
                        <span onClick={() => history.push("/login")} className="font-weight-bold auth__text-gold cursor-pointer">
                            Sign In
                        </span>
                    </div>
                    <div className="form_login">
                        <Button typeBtn="primary" className="w-100" onAction={handleLogin} disabled={errorText?.email || !values.email}>
                            Send Intruction
                        </Button>
                    </div>
                </div>
            </div>
        </AuthLayout2>
    );
};

export default Component;
