import { AxiosError } from "axios";
import { IMiddleResponse } from "./middlewareTypes";

//ticketing start
export interface ITicketingGetList {
  limit: 10 | 25 | 50 | 100;
  page: number;
  search: string;
  status: number;
  ticket_type: 1 | 2 | 3 | 4;
  user_id: number;
  user_role: string;
}

export interface ITicketDetail {
  ticket_id: number | string;
}

export interface ITicketCreate {
  PlatformKey?: string;
  type_ticket: "1";
  channel_no: string;
  admin_name: string;
  customer_ticket_user_id: string;
  customer_ticket_client_id: string;
  name: string;
  member_type: string;
  phone_no: string;
  national_id_number: string;
  email: string;
  request_date: string;
  reason_to_delete: string;
  channel: string;
  note: string;
  total_balance: string;
  request: string;
  account_no: string;
  account_name: string;
  bank: string;
  id_card_image: string;
  screenshot_image: string;
}

export interface ITicketCreateRefund {
  PlatformKey?: string;
  type_ticket: "2";
  channel_no: string;
  admin_name: string;
  customer_ticket_user_id: string;
  customer_ticket_client_id: string;
  name: string;
  member_type: string;
  phone_no: string;
  national_id_number: string;
  email: string;
  request_date: string;
  reason_to_delete: string;
  channel: string;
  note: string;
  transaction_number: string;
  date_time: string;
  transaction_type: string;
  customer_id: string;
  payment_method: string;
  transaction_amount: string;
  transaction_status: string;
  remaining_balance: string;
  reference_transaction_number: string;
  reference_number: string;
}

export interface ITickerCreateChangeProfile {
  PlatformKey?: string;
  type_ticket: "4";
  channel_no: string;
  admin_name: string;
  customer_ticket_user_id: string;
  customer_ticket_client_id: string;
  name: string;
  member_type: string;
  phone_no: string;
  national_id_number: string;
  email: string;
  request_date: string;
  reason_to_delete: string;
  channel: string;
  note: string;
  gender: string;
  dob: string;
  pob: string;
  blood_type: string;
  address: string;
  neighborhood_hamlet: string;
  province: string;
  city: string;
  village: string;
  sub_district: string;
  religion: string;
  mariage_status: string;
  profession: string;
  nationality: string;
  valid_until: string;
}

export interface ITicketCreateChangeStatus {
  PlatformKey?: string;
  type_ticket: "3";
  channel_no: string;
  admin_name: string;
  customer_ticket_user_id: string;
  customer_ticket_client_id: string;
  name: string;
  member_type: string;
  phone_no: string;
  national_id_number: string;
  email: string;
  request_date: string;
  reason_to_delete: string;
  channel: string;
  note: string;
  current_status: string;
  change_status: string;
}

//ticketing end

export enum TicketTypes {
  TICKET_SUCCESS = "TICKET_SUCCESS",
  TICKET_PENDING = "TICKET_PENDING",
  TICKET_ERROR = "TICKET_ERROR",
}
export type ITicketDataIn = {
  ClientId: number;
  Subject: "ticketing" | "ticket" | "digitalid";
  SubjectType: "ticketing" | "ticket" | "enrollment";
  ResourceType: string;
  Resource: string;
  ResourceId: string;
  UserId: number;
  Date: string;
  PlatformKey?: string;
} & (
  | ticketGetList
  | ticketDetail
  | updateTicket
  | updateTicketCSStaff
  | uploadPhoto
  | updateTicketFinStaff
  | updateTicketFinSpv
  | ticketCreateCloseAccount
  | ticketCreateRefund
  | ticketCreateChangeStatus
  | ticketCreateChangeProfile
  | ceksaldo
  | userList
);
type ticketGetList = {
  SubjectIdentifier:
    | "listcsstaff"
    | "listcsspv"
    | "listfinstaff"
    | "listfinspv";
  Data: ITicketingGetList;
  Action: string;
};

type ticketDetail = {
  SubjectIdentifier: "detailbyticketid";
  Action: string;
  Data: ITicketDetail;
};
type ticketCreateCloseAccount = {
  SubjectIdentifier: "create-ticket-closed-account";
  Action: string;
  Data: ITicketCreate;
};
type ticketCreateRefund = {
  SubjectIdentifier: "create-ticket-refund";
  Action: string;
  Data: ITicketCreateRefund;
};
type ticketCreateChangeStatus = {
  SubjectIdentifier: "create-ticket-change-status";
  Action: string;
  Data: ITicketCreateChangeStatus;
};
type ticketCreateChangeProfile = {
  SubjectIdentifier: "create-ticket-change-profile";
  Action: string;
  Data: ITickerCreateChangeProfile;
};

type updateTicket = {
  SubjectIdentifier: "updatecsspv";
  Action: string;
  Data: any;
};
type updateTicketCSStaff = {
  SubjectIdentifier: "updatecsstaff";
  Action: string;
  Data: any;
};
type updateTicketFinStaff = {
  SubjectIdentifier: "updatefinstaff";
  Action: string;
  Data: any;
};
type updateTicketFinSpv = {
  SubjectIdentifier: "updatefinspv";
  Action: string;
  Data: any;
};
type uploadPhoto = {
  SubjectIdentifier: "ektp";
  Action: "upload-photo";
  Data: {
    Photo: string;
    PhotoType: string;
  };
};
type ceksaldo = {
  SubjectIdentifier: "ceksaldo";
  Action: string;
  Data: any;
};
type userList = {
  SubjectIdentifier: "user-list";
  Action: "get";
  Data: {
    PlatformKey: string;
    ClientId: number;
    Page: number;
    Limit: number;
    Search: string;
  };
};

export interface ITicketDataOut {
  TicketId: string;
  Subject: "ticketing" | "ticket" | "digitalid";
  SubjectType: "ticketing" | "ticket" | "enrollment";
  SubjectIdentifier:
    | "listcsstaff"
    | "ektp"
    | "listcsspv"
    | "listfinstaff"
    | "listfinspv"
    | "detailbyticketid"
    | "updatecsspv"
    | "updatefinstaff"
    | "updatecsstaff"
    | "updatefinspv"
    | "create-ticket-closed-account"
    | "create-ticket-refund"
    | "create-ticket-change-status"
    | "create-ticket-change-profile"
    | "ceksaldo"
    | "user-list";
  Data?: any;
}

export type headersMiddelware = {
  "Idempotency-Key": string;
};

interface GetTicketAction {
  type: TicketTypes.TICKET_SUCCESS;
  payload: {
    data: IMiddleResponse;
  };
}
interface SetLoadingTicketAction {
  type: TicketTypes.TICKET_PENDING;
  payload?: any;
}

interface SetErrorTicketAction {
  type: TicketTypes.TICKET_ERROR;
  payload: {
    error: AxiosError;
  };
}

export interface ITicketState {
  data: any;
  isLoading: boolean;
  error: AxiosError;
}

export type TicketAction =
  | GetTicketAction
  | SetLoadingTicketAction
  | SetErrorTicketAction;
