import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import { RootState } from "..";
import {
  changePassword,
  forgotPassword,
  initialization,
  login,
  refreshToken,
} from "../actions2/authAction";
import { IAuthData, IAuthResponse } from "../types/authTypes";
import { removeCookie } from "../../utils/cookie";

const useAuth = () => {
  const dispatch = useDispatch();
  const authState = useSelector((state: RootState) => state.auth);

  const handleInitialization = useCallback(
    (body: IAuthData, callback?: (data: IAuthResponse) => void) => {
      dispatch(initialization(body, callback));
    },
    [dispatch]
  );

  const handleLogin = useCallback(
    (body: any, headers?: any, callback?: (data: IAuthResponse) => void) => {
      dispatch(login(body, headers, callback));
    },
    [dispatch]
  );
  const handleRefreshToken = useCallback(
    (body: any, callback?: (data: any) => void) => {
      dispatch(refreshToken(body, callback));
    },
    [dispatch]
  );

  const handleForgotPassword = useCallback(
    (body: any, callback?: (data: IAuthResponse) => void) => {
      dispatch(forgotPassword(body, callback));
    },
    [dispatch]
  );

  const handleChangePassword = useCallback(
    (body: IAuthData, callback?: (data: IAuthResponse) => void) => {
      dispatch(changePassword(body, callback));
    },
    [dispatch]
  );

  const handleLogout = () => {
    localStorage.clear();
    window.location.replace("/login");
  };

  return {
    authState,
    changePassword: handleChangePassword,
    initialization: handleInitialization,
    login: handleLogin,
    forgotPassword: handleForgotPassword,
    logout: handleLogout,
    refreshToken: handleRefreshToken,
  };
};

export default useAuth;
