export const getAuthToken = () => {
  return localStorage.getItem("token_keycloak");
};

export const getAuthTokenSass = () => {
  return localStorage.getItem("token_sass");
};

export const setAuthToken = (token: string): void => {
  return localStorage.setItem("token_keycloak", token);
};

export const removeAuthToken = (): void => {
  return localStorage.clear();
};
