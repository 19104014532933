import {
  ITrxListState,
  TrxListAction,
  TrxListType,
} from "../types/transactionListTypes";

const initialState = {
  data: null,
  dataDetail: null,
  dataExport: null,
  isLoadingDDl: false,
  isLoading: false,
  isLoadingDetail: false,
  isLoadingExport: false,
  error: null,
};

export default (
  state: ITrxListState = initialState,
  { type, payload }: TrxListAction
) => {
  switch (type) {
    case TrxListType.TRXLIST_PENDING:
      return { ...state, ...payload, error: null };
    case TrxListType.TRXLIST_SUCCESS:
      return { ...state, ...payload };
    case TrxListType.TRXLIST_ERROR:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
};
