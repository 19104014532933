import {
  MiddlewareAction,
  MiddlewareTypes,
  IMiddlewareState,
} from "../types/middlewareTypes";

const initialState = {
  data: {},
  isLoading: false,
  error: null,
};

export default (
  state: IMiddlewareState = initialState,
  { type, payload }: MiddlewareAction
) => {
  switch (type) {
    case MiddlewareTypes.MIDDLEWARE_IN_PENDING:
      return { ...state, ...payload, error: null, isLoading: true };
    case MiddlewareTypes.MIDDLEWARE_OUT_PENDING:
      return { ...state, ...payload, error: null, isLoading: true };
    case MiddlewareTypes.MIDDLEWARE_IN_SUCCESS:
      return { ...state, ...payload, isLoading: true };
    case MiddlewareTypes.MIDDLEWARE_OUT_SUCCESS:
      return { ...state, ...payload, isLoading: false };
    case MiddlewareTypes.MIDDLEWARE_IN_ERROR:
      return {
        ...state,
        ...payload,
        isLoading: false,
        data: {},
      };
    case MiddlewareTypes.MIDDLEWARE_OUT_ERROR:
      return {
        ...state,
        ...payload,
        isLoading: false,
        data: {},
      };

    default:
      return state;
  }
};
