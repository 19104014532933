import { AxiosError } from "axios";

export enum AuthType {
  AUTH_PENDING = "AUTH_PENDING",
  AUTH_SUCCESS = "AUTH_SUCCESS",
  AUTH_ERROR = "AUTH_ERROR",
}

export interface IAuthData {
  OldPassword?: string;
  NewPassword?: string;
  PasswordConfirmation?: string;
  Password?: string;
  Email?: string;
  Platform?: string;
  Platformkey?: string;
  Token?: string;
  Secret: string;
}

export interface IAuthResponse {
  Code: number;
  StatusCode: number;
  Message: string;
  Data: IAuthData[] & IAuthData;
  code: number;
  data: any;
  message: string;
}

interface GetAuthAction {
  type: AuthType.AUTH_SUCCESS;
  payload: {
    data: IAuthResponse;
  };
}

interface SetLoadingAuthAction {
  type: AuthType.AUTH_PENDING;
  payload?: any;
}

interface SetErrorAuthAction {
  type: AuthType.AUTH_ERROR;
  payload: {
    error: AxiosError;
  };
}

export interface IAuthState {
  data: IAuthData[] & IAuthData;
  isLoading: boolean;
  error: AxiosError;
}

export type AuthAction =
  | GetAuthAction
  | SetLoadingAuthAction
  | SetErrorAuthAction;
