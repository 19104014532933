import { IRefundState, RefundAction, RefundType } from "../types/refundTypes";

const initialState = {
  data: null,
  dataDetail: null,
  dataExport: null,
  isLoadingDDl: false,
  isLoading: false,
  isLoadingDetail: false,
  isLoadingExport: false,
  error: null,
  dataWithReceipt: null,
  isLoadingCreate: false,
};

export default (
  state: IRefundState = initialState,
  { type, payload }: RefundAction
) => {
  switch (type) {
    case RefundType.REFUND_PENDING:
      return { ...state, ...payload, error: null };
    case RefundType.REFUND_SUCCESS:
      return { ...state, ...payload };
    case RefundType.REFUND_ERROR:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
};
