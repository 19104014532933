import { AdjAction, AdjType, IAdjState } from "../types/adjudicatorTypes";

const initialState = {
  data: {
    code: "",
    status: "",
    message: "",
    data: null,
    paginate: {
      page: 1,
      total_page: 0,
      total_record: 0,
      limit: 10,
    },
  },
  isLoading: false,
  error: null,
};

export default (
  state: IAdjState = initialState,
  { type, payload }: AdjAction
) => {
  switch (type) {
    case AdjType.ADJ_PENDING:
      return { ...state, ...payload, error: null, isLoading: true };
    case AdjType.ADJ_SUCCESS:
      return { ...state, ...payload, isLoading: false };
    case AdjType.ADJ_ERROR:
      return {
        ...state,
        ...payload,
        isLoading: false,
        data: {
          code: "",
          status: "",
          message: "",
          data: null,
          paginate: {
            page: 1,
            total_page: 0,
            total_record: 0,
            limit: 10,
          },
        },
      };

    default:
      return state;
  }
};
