import React, { useCallback, useEffect, useState } from "react";
import { Button } from "../../componentv2/atoms/NewButton";
import AuthLayout2 from "../../containers/AuthLayout2";
import { useKeycloak } from "@react-keycloak/web";
import { handleLoginResponse, keycloakLogin, keycloakLogout, refreshKeycloakToken } from "../../utils/keycloak-auth";
import { IAuthData } from "../../redux/types/authTypes";
import useAuth from "../../redux/hooks/authHooks";

const Component: React.FC = () => {
    const { initialization, login } = useAuth();
    const popupCenter = (url: string, title: string) => {
        const dualScreenLeft = window.screenLeft || window.screenX;
        const dualScreenTop = window.screenTop || window.screenY;

        const width = window.innerWidth || document.documentElement.clientWidth;
        const height = window.innerHeight || document.documentElement.clientHeight;

        const systemZoom = window.screen.availWidth / width;

        const targetWidth = 550;
        const targetHeight = 500;

        const left = (window.screen.availWidth - targetWidth) / 2 / systemZoom + dualScreenLeft;
        const top = (window.screen.availHeight - targetHeight) / 2 / systemZoom + dualScreenTop;

        const newWindow = window.open(
            url,
            title,
            `width=${targetWidth},height=${targetHeight},top=${top},left=${left},scrollbars=no,resizable=no`
        );

        newWindow?.focus();
    };







    useEffect(() => {
        handleLoginResponse()
        // handleInit()
    }, [])

    useEffect(() => {
        handleInit();
    }, []);

    const handleInit = () => {
        const body: IAuthData = {
            Platform: process.env.REACT_APP_CLIENT_PLATFORM,
            Secret: process.env.REACT_APP_CLIENT_SECRET
            // Platform: "admin-qoinpay",
            // Secret: "2EZVPZFKBVNHDSSJKHRG7X",
        };

        initialization(body, (res) => {
            if (res?.StatusCode === 200) {
                localStorage.setItem("platform-key", res.Data.Platformkey);

            }

        });
    };


    return (
        <AuthLayout2>
            <div className="login_page w-100">
                <div className="font-weight-bolder h4 text-dark mb-3">Sign In</div>
                <div>


                    <div className="form_login mt-4">
                        <Button
                            typeBtn="primary"
                            className="w-100 "
                            onAction={() => popupCenter("/keycloak", "Qoinapps")}
                        >
                            Sign In
                        </Button>
                    </div>
                    {/* <div className="form_login mt-4">
                        <Button
                            typeBtn="primary"
                            className="w-100 "
                            onAction={refreshKeycloakToken}
                        >
                            Refresh Token
                        </Button>
                    </div>
                    <div className="form_login mt-4">
                        <Button
                            typeBtn="primary"
                            className="w-100 "
                            onAction={keycloakLogout}
                        >
                            Log out
                        </Button>
                    </div> */}


                </div>
            </div>
        </AuthLayout2>
    );
};

export default Component;
