import React from "react";
import { IAuthLayout } from "./interface";
import { AUTH_IMAGE, QOIN_TEXT_LOGO, SHAPE_IMAGE } from "../../assets/img2";
import "./styles.scss";

const Component: React.FC<IAuthLayout> = ({ children }) => {
    return (
        <>
            <div className="auth_layout flex-column flex-lg-row">
                <div className="auth_default w-100">
                    <div className="d-lg-flex d-none auth__subheader mt-lg-5 mt-3">
                        <img className="auth__logo" src={QOIN_TEXT_LOGO} alt="" />
                        <h1 className="ml-4 d-none d-lg-block auth__title">Qoin Admin</h1>
                        <h4 className="ml-4 d-block d-lg-none auth__title">
                            Qoin Admin
                        </h4>
                    </div>
                    <div className="d-flex justify-content-center h-100 ">
                        <img className="auth__left-vector " src={AUTH_IMAGE} alt="" />
                    </div>
                </div>
                <div className="auth_menu w-100">
                    <img
                        className="position-absolute justify-content-end d-flex"
                        style={{ right: 30, top: 30, height: "auto", width: "14%" }}
                        src={SHAPE_IMAGE}
                        alt=""
                    />
                    {children}
                </div>
            </div>
        </>
    );
};

export default Component;
