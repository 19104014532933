import { updateObject } from "../../utils/utility";
import { AuthAction, AuthType, IAuthState } from "../types/authTypes";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

export default (
  state: IAuthState = initialState,
  { type, payload }: AuthAction
) => {
  switch (type) {
    case AuthType.AUTH_PENDING:
      return updateObject(state, { ...payload, error: null, isLoading: true });
    case AuthType.AUTH_SUCCESS:
      return updateObject(state, { ...payload, isLoading: false });
    case AuthType.AUTH_ERROR:
      return updateObject(state, { ...payload, isLoading: false });

    default:
      return state;
  }
};
