import React, { useEffect, useState } from "react";
import { IDropdown } from "./interface";
import "./styles.scss";

const Component: React.FC<IDropdown> = ({
  data,
  filterText,
  setValue,
  className,
  disable,
}) => {
  const [options, setOptions] = useState<any>([]);

  useEffect(() => {
    const dataOption = data?.filter(
      (dataItem: { name: string; value: string | number; desc: string }) =>
        !filterText ||
        dataItem?.name?.toLowerCase().includes(filterText?.toLowerCase())
    );

    setOptions(
      dataOption.length < 1
        ? [{ name: "No options", value: "", desc: "" }]
        : dataOption
    );
  }, [data, filterText]);

  return (
    <div className={`dropdown-comp ${className}`}>
      {options?.map(
        (
          dataOptions: {
            desc: string;
            value: string | number;
            name: string | number;
          },
          idx: React.Key
        ) => {
          return (
            <>
              <button
                className={`py-2 w-100 text-left`}
                key={idx}
                onClick={(evt) => {
                  evt?.preventDefault();
                  setValue(dataOptions);
                }}
                disabled={dataOptions?.name === "" || disable}
                value={dataOptions?.name}
              >
                <span
                  style={{
                    fontSize: "17px",
                  }}
                >
                  {dataOptions?.name}
                </span>
                <p
                  style={{
                    fontSize: "12px",
                  }}
                >
                  {dataOptions?.desc}
                </p>
              </button>
            </>
          );
        }
      )}
    </div>
  );
};

export default Component;
