import { AxiosError } from "axios";
import { IMiddleResponse } from "./middlewareTypes";

export enum ReportType {
  REPORT_PENDING = "REPORT_PENDING",
  REPORT_SUCCESS = "REPORT_SUCCESS",
  REPORT_ERROR = "REPORT_ERROR",
}

export interface IParamReport {
  Limit?: number;
  Page?: number;
  Search?: string;
  Status?: string;
  StartDate?: string;
  EndDate?: string;
  Id?: number;
  Destination?: string;
  Product?: any;
  Payment?: string;
  OrderType?: any;
}

export interface IReportDataMaster {
  trxId: number;
  trxClientId: number;
  trxMemberId: number;
  trxType: number;
  trxOrderType: string;
  trxPaymentType: string;
  trxDate: string;
  trxReceipt: string;
  trxUser: string;
  trxStatus: string;
  trxPhone: string;
  trxPayload: string;
  pGroup: string;
  pName: string;
  mArea: string;
}

export interface IReportResponseMaster {
  Code: number;
  Status: string;
  Message: string;
  Data: IReportDataMaster;
  Paginate: {
    CurrentPage: number;
    NextPage: number;
    PrevPage: number;
    TotalPage: number;
    TotalRow: number;
  };
}

export interface IMiddlewareBodyReportIn {
  ClientId: number;
  Subject: "ewallet";
  SubjectType: "webreport";
  SubjectIdentifier:
    | "qris"
    | "ppob"
    | "topup"
    | "transferout"
    | "voucher"
    | "all"
    | "list_order_type"
    | "product";
  Action: "master" | "detail" | "export";
  ResourceType: "null";
  Resource: "null";
  ResourceId: "null";
  UserId: string;
  Date: string;
  Data: IParamReport;
}

export interface IMiddlewareBodyReportOut {
  TicketId: string;
  Subject: "ewallet";
  SubjectType: "webreport";
  SubjectIdentifier:
    | "qris"
    | "ppob"
    | "topup"
    | "transferout"
    | "voucher"
    | "all"
    | "list_order_type"
    | "product";
}

export interface IMiddlewareResponseReportOut {
  ClientId: number;
  Subject: "ewallet";
  SubjectType: "webreport";
  SubjectIdentifier:
    | "qris"
    | "ppob"
    | "topup"
    | "transferout"
    | "voucher"
    | "all"
    | "list_order_type"
    | "product";
  Action: "master" | "detail" | "export";
  ResourceType: null;
  Resource: null;
  ResourceId: null;
  UserId: string;
  Date: string;
  Result: IReportResponseMaster | any;
  Status?: string;
  StatusCode?: number;
}

interface GetReportAction {
  type: ReportType.REPORT_SUCCESS;
  payload: {
    data: IMiddlewareResponseReportOut | IMiddleResponse | null;
  };
}

interface SetLoadingReportAction {
  type: ReportType.REPORT_PENDING;
  payload?: {
    data: any;
  };
}

interface SetErrorReportAction {
  type: ReportType.REPORT_ERROR;
  payload: {
    error: AxiosError;
  };
}

export interface IReportState {
  data: IMiddlewareResponseReportOut | IMiddleResponse;
  isLoading: boolean;
  error: AxiosError;
  dataDetail: any;
  isLoadingDetail: boolean;
  dataExport: any;
  isLoadingExport: boolean;
  isLoadingDDl: boolean;
}

export type ReportAction =
  | GetReportAction
  | SetLoadingReportAction
  | SetErrorReportAction;
