import { toast } from "react-toastify";
import { useEffect, useState } from "react";
// import { IcNotifFailed, IcNotifLoading, IcNotifSuccess } from 'assets/svgs';

export const updateObject = (oldObject: any, updatedProperties: any) => ({
  ...oldObject,
  ...updatedProperties,
});

export const decimalGenerate = (val: number = 16) => {
  let value = "1";
  for (var i = 0; i < val; i++) {
    value = value + "0";
  }
  let returnVal = parseInt(value);

  return returnVal;
};

export const formatSubstring = (str: String) => {
  const firstChar = str?.substring(0, 4);
  const lastChar = str?.substring(str?.length - 4);

  return `${firstChar || ""}...${lastChar || ""}`;
};

export const capitalize = (str: string) => {
  return (str || "").toLowerCase().replace(/\b(\w)/g, (x) => x.toUpperCase());
};

export const notification = (
  type: "success" | "error" | "info" | "warning",
  message: string
) => {
  if (type === "success") {
    // toast.dismiss();
    const toastID = toast.success(message, {
      theme: "light",
      // icon: IcNotifSuccess,
    });
    const timerToast = setTimeout(() => {
      // toast.dismiss(toastID);
      clearTimeout(timerToast);
    }, 5000);
  } else if (type === "error") {
    // toast.dismiss();
    const toastID = toast.error(message, {
      theme: "light",
      // icon: IcNotifFailed,
    });
    const timerToast = setTimeout(() => {
      // toast.dismiss(toastID);
      clearTimeout(timerToast);
    }, 5000);
  } else if (type === "info") {
    // toast.dismiss();
    const toastID = toast.info(message, {
      theme: "light",
      // icon: IcNotifFailed,
    });
    const timerToast = setTimeout(() => {
      // toast.dismiss(toastID);
      clearTimeout(timerToast);
    }, 5000);
  } else if (type === "warning") {
    // toast.dismiss();
    toast.warning(message, {
      theme: "light",
      // icon: IcNotifLoading,
    });
    const timerToast = setTimeout(() => {
      // toast.dismiss(toastID);
      clearTimeout(timerToast);
    }, 5000);
  } else {
    toast.dismiss();
  }
};

export const firstLatter = (str: string) => {
  const splitName = str.split(" ");

  return capitalize(splitName[0].charAt(0));
};

export const encodeGetAccount = (token: any) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const environment = {
  web_url: process.env.REACT_APP_WEB_URL,
  fee_payer: process.env.REACT_APP_FEE_PAYER,
  qtag: process.env.REACT_APP_QTAG,
};

export const useWindowDimensions = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    const handleResize = () =>
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return windowSize;
};
