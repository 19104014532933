import React from "react";
import { IButton } from "./interface";
import "./button.scss";

const Component: React.FC<IButton> = (props) => {
  const {
    size,
    typeBtn,
    icon,
    children,
    onAction,
    position,
    className,
    styles,
    disabled,
    loading,
    type,
  } = props;

  return (
    <>
      <div>
        <button
          className={`button-styles ${typeBtn || "primary"} ${size || "lg"} ${
            className || ""
          }`}
          style={styles}
          onClick={onAction}
          disabled={disabled || loading}
          type={type}
        >
          <div className="d-flex justify-content-center align-items-center">
            {icon && position !== "right" && (
              <img
                src={icon}
                height={20}
                width={20}
                alt=""
                className="mr-2 filter-icon"
              />
            )}

            {loading ? (
              <>
                <span className="spinner-border text-warning"></span>
              </>
            ) : (
              <>{children}</>
            )}
            {icon && position === "right" && (
              <img
                src={icon}
                height={20}
                width={20}
                alt=""
                className="ml-2 filter-icon"
              />
            )}
          </div>
        </button>
      </div>
    </>
  );
};

export default Component;
