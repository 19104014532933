import { Spinner } from "reactstrap";
import logo from "../assets/img2/qoin.png";

const Loading = (bg = true) => (
  <div className={`loading ${bg ? "bg" : ""}`}>
    <div className="img-load">
      {/* <Spinner size="lg" color="warning" /> */}
      <img src={logo} alt="" className="spin" />
    </div>
  </div>
);

export default Loading;
