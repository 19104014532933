const endpoints = {
  accessV2: {
    initialization: "access/v2/initialization",
    login: "access/v2/login/app",
    getProfile: "access/v2/platform/profile/detail",
    editProfile: "access/v2/platform/profile/edit",
    forgotPassword: "access/v2/platform/password/forgot",
    changePassword: "access/v2/platform/password/change",
    getPermissionLogin: "access/v2/client/platform/rolepermission-list",
  },
  middleware: {
    in: "saas/requestin",
    inV3: "saas/v3/requestin",
    outV3: "saas/v3/requestout",
    out: "saas/requestout",
  },
  initialization: "access/initialization",
  login: "access/login/web",
  refreshToken: "access/token/refresh",
  // userList: "access/v2/users/client/list",
  userList: "access/user/list",
  userDetails: "access/user/detail",
  addUser: "access/v2/user/create",
  masterRole: "access/v2/roles/master",
  roleList: "access/roles/list",
  createRole: "access/roles/add",
  updateRole: "access/roles/edit",
  rolePermission: "access/role-permission/",
  reportPPOB: "webreport/transaction",
  reportTopup: "webreport/transaction-topup",
  reportTransfer: "webreport/transactiontransfer-out",
  masterProduct: "webreport/masterproduct",
  masterArea: "webreport/masterarea",
  masterStatus: "webreport/masterstatus",
  masterPayment: "webreport/mastertypepayment",
  listadjsupervisor: "organization-user-approval/list",
  listadjoperation: "organizationuser/list",
  listadjsuperadmin: "listadjudicator",
  updateAdjudicator: "adjudicator",
  ajudikatorDetail: "detail-adjudicator",
  postVida: "document/adjudicator/vida/async",
  getVida: "document/adjudicator/vida/async/get",
  getDocProfile: "ticketing",
  getDetailUpgrade: "v1/adjudicator/user",
  checkAcumalateSaldo: "v30/Web/PostDataVaBagByRef",
  getTransactionListCust: "web/transaction-history-list",
  getTransactionByReceipt: "web/transaction-history",
  customerLog: "customer",
  adminLog: "admin",
  ticketList: "ticketing/list",
  getUserTicket: "v1/userticket",
  checkAvailaleTicket: "ticket/check-ticket",
  checkSaldo: "ticketing/check-saldo",
  uploadPhotoDoc: "document/upload-photo",
  createTicket: "ticket/create",
  detailsTicket: "ticketing-details",
  updateStatusTicket: "ticketing-queue/update-status",
};

export default endpoints;
