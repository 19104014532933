import {
  AdditionalFeeAction,
  AdditionalFeeType,
  IAdditionalFeeState,
} from "../types/additionalFeeTypes";

const initialState = {
  data: null,
  dataDetail: null,
  dataExport: null,
  isLoadingDDl: false,
  isLoading: false,
  isLoadingDetail: false,
  isLoadingExport: false,
  error: null,
  isLoadingCreate: false,
  isLoadingDelete: false,
  isLoadingApproval: false,
};

export default (
  state: IAdditionalFeeState = initialState,
  { type, payload }: AdditionalFeeAction
) => {
  switch (type) {
    case AdditionalFeeType.ADDITIONALFEE_PENDING:
      return { ...state, ...payload, error: null };
    case AdditionalFeeType.ADDITIONALFEE_SUCCESS:
      return { ...state, ...payload };
    case AdditionalFeeType.ADDITIONALFEE_ERROR:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
};
