import classNames from "classnames";
import React, { useState } from "react";
import Dropdown from "../DropdownDesc";
import { IDropdown } from "../Dropdown/interface";
import { IInputSelect } from "./interface";

import "./input.scss";
import { ICON_CLOSE } from "../../../assets/icon2";

interface ICombination extends IInputSelect, IDropdown {
  disable?: boolean;
}

const Component: React.FC<ICombination> = ({
  className,
  onChange,
  value,
  error,
  style,
  name,
  data,
  filterText,
  setValue,
  isClearable,
  disable,
  ...prop
}) => {
  let clearable = isClearable === false ? false : true;

  const [showDropDown, setShowDropDown] = useState<boolean>(false);
  const handleFocus = () => {
    setShowDropDown(true);
  };
  const handleBlur = () => {
    setTimeout(() => {
      setShowDropDown(false);
    }, 100);
  };

  return (
    <>
      <div className="input-comp ">
        <div className="d-flex">
          <input
            className={classNames(`${className}`, {
              error: error,
            })}
            onFocus={handleFocus}
            onBlur={handleBlur}
            style={style}
            onChange={onChange}
            value={value || ""}
            name={name}
            autoComplete="off"
            {...prop}
          />
          {clearable && (
            <>
              <span
                className={`show-icon right cursor-pointer`}
                onClick={() =>
                  setValue({
                    name: "",
                    value: "",
                  })
                }
              >
                <img src={ICON_CLOSE} width={10} height={10} alt="" />
              </span>
            </>
          )}
        </div>
        {showDropDown && (
          <Dropdown
            data={data}
            filterText={filterText}
            setValue={setValue}
            disable={disable}
          />
        )}
      </div>
    </>
  );
};

export default Component;
