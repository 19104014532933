import React, { useEffect, useState } from "react";
import { handleLoginResponse, keycloakLogin } from "../../utils/keycloak-auth";



const PopupKeycloak = () => {

    useEffect(() => {
        const code = new URLSearchParams(window.location.search).get("code");

        // validate code not null or undefined
        if (!code) {
            keycloakLogin()
        } else {
            handleLoginResponse()
        }

    }, [1]);

    return (
        <>
            {/* <div
                style={{
                    width: "500px",
                    height: "400px",
                    position: "absolute",
                    left: 0,
                    top: 0,
                    background: "white",
                }}
            ></div> */}
        </>
    );
}

export default PopupKeycloak 