import { AxiosError } from "axios";

export enum UserType {
  USER_LIST_PENDING = "USER_LIST_PENDING",
  USER_LIST_SUCCESS = "USER_LIST_SUCCESS",
  USER_LIST_ERROR = "USER_LIST_ERROR",
}

export interface IUserState {
  data: IUserData[] & IUserData;
  isLoading: boolean;
  error: AxiosError;
}

interface GetUserAction {
  type: UserType.USER_LIST_SUCCESS;
  payload: {
    data: IUserResponse;
  };
}

interface SetLoadingUserAction {
  type: UserType.USER_LIST_PENDING;
  payload?: any;
}

interface SetErrorUserAction {
  type: UserType.USER_LIST_ERROR;
  payload: {
    error: AxiosError;
  };
}

export interface IParamUsers {
  page?: number;
  limit?: number;
  search?: string;
  totalPages?: number;
}

export interface IUserData {
  records?: [
    {
      Id?: number | string;
      Fullname: string;
      Phone: number | string;
      Email: string;
      RoleId: number;
      Password: string;
      PasswordConfirmation: string;
      Nik?: number;
      ClientId?: number | string;
    }
  ];
  total_page?: number;
}

export interface IUserResponse {
  // requestId: string;
  // status: string;
  code?: number;
  StatusCode?: number;
  message: string;
  paginate: {
    current_page: number;
    total_page: number;
    total_row: number;
    per_page: number;
  };
  data: IUserData[] & IUserData;
}

export type UserAction =
  | GetUserAction
  | SetLoadingUserAction
  | SetErrorUserAction;
