import React from "react";
import { IBanner } from "./interface";
import { ICON_ALERT, ICON_CLOSE } from "../../../assets/icon2";
import "./styles.scss"

const Component: React.FC<IBanner> = (props) => {
    const { bannerType, onAction, message, icon } = props

    return (
        <>
            <div className={`banner-comp ${bannerType}`}>
                <div className="icon">
                    <img src={ICON_ALERT} className="alert-icon" width={26} alt="alert-icon" />
                </div>
                <div className="message text-capitalize">
                    {message || "Lorem Ipsum Dolor Set Amet"}
                </div>
                <div className="icon cursor-pointer"  >
                    <img src={ICON_CLOSE} alt="close-icon" width={18} onClick={onAction} />
                </div>
            </div>
        </>
    );
};

export default Component;
