import React, { useState } from "react";
import { IInput } from "./interface";
import "./input.scss";
import { IDataDropdown, IDropdown } from "../Dropdown/interface";
import Dropdown from "../Dropdown";
import classNames from "classnames";
import { ICON_EYE, ICON_EYE_SLASH } from "../../../assets/icon2";

interface ICombination extends IInput, IDropdown { }

const Component: React.FC<ICombination> = (props) => {
  const {
    data,
    filterText,
    setValue,
    error,
    validText,
    min,
    max,
    style,
    onInput,
    iconPassword, icon, iconPosition, type,
    ...prop
  } = props;
  const [showPassword, setShowPassword] = useState<boolean>(false)

  return (
    <>
      <div className="input-comp">
        <div className="d-flex">
          {iconPassword && iconPosition === "left" && (
            <span className={`show-icon left`}>
              {showPassword ? (

                <img src={ICON_EYE_SLASH} width={20} className="cursor-pointer" height={20} alt="" onClick={() => setShowPassword(false)} />
              ) : (
                <img src={ICON_EYE} width={20} className="cursor-pointer" height={20} alt="" onClick={() => setShowPassword(true)} />

              )}
            </span>
          )}
          {icon && iconPosition === "left" && (
            <span className={`show-icon left`}>
              <img src={icon} width={20} height={20} alt="" />
            </span>
          )}
          <input
            className={classNames(`${prop.className}`, {
              error: error,
            })}
            // required
            type={iconPassword ? (showPassword ? "text" : "password") : type}
            pattern={prop?.pattern}
            onChange={prop?.onChange}
            value={prop?.value}
            minLength={min}
            maxLength={max}
            style={style}
            onInput={onInput}
            {...prop}
          />
          {iconPassword && iconPosition === "right" && (
            <span className={`show-icon right`}>
              {showPassword ? (

                <img src={ICON_EYE_SLASH} width={20} className="cursor-pointer" height={20} alt="" onClick={() => setShowPassword(false)} />
              ) : (
                <img src={ICON_EYE} width={20} className="cursor-pointer" height={20} alt="" onClick={() => setShowPassword(true)} />

              )}
            </span>
          )}

          {icon && iconPosition === "right" && (
            <span className={`show-icon right`}>
              <img src={icon} width={20} height={20} alt="" />
            </span>
          )}
        </div>
        {validText && (
          <span
            className={classNames("text-validation", {
              error: error,
            })}
          >
            {validText}
          </span>
        )}
      </div>
    </>
  );
};

export default Component;
