import { AxiosError } from "axios";

export enum RoleType {
  ROLE_LIST_PENDING = "ROLE_LIST_PENDING",
  ROLE_LIST_SUCCESS = "ROLE_LIST_SUCCESS",
  ROLE_LIST_ERROR = "ROLE_LIST_ERROR",
}

export interface IRoleData {
  Id?: number | string;
  RoleName: string;
  Type?: string;
  RoleType: string;
  ClientId?: number;
}

export interface IRoleResponse {
  code: number;
  message: string;
  Paginate: {
    CurrentPage: number;
    TotalPage: number;
    TotalRow: number;
    PerPage: number;
  };
  data: IRoleData[] & IRoleData;
}

interface GetRoleAction {
  type: RoleType.ROLE_LIST_SUCCESS;
  payload: {
    data: IRoleResponse;
  };
}

interface SetLoadingRoleAction {
  type: RoleType.ROLE_LIST_PENDING;
  payload?: any;
}

interface SetErrorAction {
  type: RoleType.ROLE_LIST_ERROR;
  payload: {
    error: AxiosError;
  };
}

export interface IParamRole {
  limit?: number;
  page?: number;
  sort?: string;
  totalPages?: number;
}

export interface IRoleState {
  data: (IRoleData[] & IRoleData) | null;
  isLoading: boolean;
  error: AxiosError | null;
}

export type RoleAction = GetRoleAction | SetLoadingRoleAction | SetErrorAction;
