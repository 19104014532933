import {
  TicketAction,
  TicketTypes,
  ITicketState,
} from "../types/ticketingTypesV2";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

export default (
  state: ITicketState = initialState,
  { type, payload }: TicketAction
) => {
  switch (type) {
    case TicketTypes.TICKET_PENDING:
      return { ...state, ...payload, error: null };
    case TicketTypes.TICKET_SUCCESS:
      return { ...state, ...payload };
    case TicketTypes.TICKET_ERROR:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
};
