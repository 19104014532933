import { AxiosError } from "axios";
import {
  IAdjudicatorDetailBody,
  IAdjudicatorListBody,
  IAdjudicatorUpdateBody,
  IAdjudicatorVidaCheck,
  ITickerCreateChangeProfile,
  ITicketCreate,
  ITicketCreateChangeStatus,
  ITicketCreateRefund,
  ITicketDetail,
  ITicketingGetList,
} from "./middlewareRequestTypes";

export enum MiddlewareTypes {
  MIDDLEWARE_IN_PENDING = "MIDDLEWARE_IN_PENDING",
  MIDDLEWARE_OUT_PENDING = "MIDDLEWARE_OUT_PENDING",
  MIDDLEWARE_IN_SUCCESS = "MIDDLEWARE_IN_SUCCESS",
  MIDDLEWARE_OUT_SUCCESS = "MIDDLEWARE_OUT_SUCCESS",
  MIDDLEWARE_IN_ERROR = "MIDDLEWARE_IN_ERROR",
  MIDDLEWARE_OUT_ERROR = "MIDDLEWARE_OUT_ERROR",
}
export type IMiddlewareDataIn = {
  ClientId: number;
  Subject: "adjudicator" | "digitalid" | "ticketing" | "ticket";
  SubjectType: "adjudicator" | "enrollment" | "ticketing" | "ticket" | "paspor";
  //   SubjectIdentifier:
  //     | "adjupdate"
  //     | "listadmin"
  //     | "listspv"
  //     | "liststaff"
  //     | "detailbyid";
  // Action: string;
  ResourceType: string;
  Resource: string;
  ResourceId: string;
  UserId: number;
  Date: string;
  PlatformKey?: string;
  //   Data: IAdjudicatorListBody | IAdjudicatorDetailBody | IAdjudicatorUpdateBody;
} & (
  | adjutListAdmin
  | adjutListSpv
  | adjutListStaff
  | adjutDetail
  | adjutUpdate
  | adjVidaCheck
  | ticketGetList
  | ticketDetail
  | updateTicket
  | updateTicketCSStaff
  | uploadPhoto
  | updateTicketFinStaff
  | updateTicketFinSpv
  | ticketCreateCloseAccount
  | ticketCreateRefund
  | ticketCreateChangeStatus
  | ticketCreateChangeProfile
  | ceksaldo
);
type adjutListAdmin = {
  SubjectIdentifier: "listadmin";
  Action: string;
  Data: IAdjudicatorListBody;
};
type adjutListSpv = {
  SubjectIdentifier: "listspv";
  Action: string;
  Data: IAdjudicatorListBody;
};
type adjutListStaff = {
  SubjectIdentifier: "liststaff";
  Action: string;
  Data: IAdjudicatorListBody;
};
type adjutDetail = {
  SubjectIdentifier: "detailbyid";
  Action: string;
  Data: IAdjudicatorDetailBody;
};
type adjutUpdate = {
  SubjectIdentifier: "adjupdate";
  Action: string;
  Data: IAdjudicatorUpdateBody;
};
type adjVidaCheck = {
  SubjectIdentifier: "web-admin";
  Action: string;
  Data: IAdjudicatorVidaCheck;
};
type ticketGetList = {
  SubjectIdentifier:
    | "listcsstaff"
    | "listcsspv"
    | "listfinstaff"
    | "listfinspv";
  Data: ITicketingGetList;
  Action: string;
};

type ticketDetail = {
  SubjectIdentifier: "detailbyticketid";
  Action: string;
  Data: ITicketDetail;
};
type ticketCreateCloseAccount = {
  SubjectIdentifier: "create-ticket-closed-account";
  Action: string;
  Data: ITicketCreate;
};
type ticketCreateRefund = {
  SubjectIdentifier: "create-ticket-refund";
  Action: string;
  Data: ITicketCreateRefund;
};
type ticketCreateChangeStatus = {
  SubjectIdentifier: "create-ticket-change-status";
  Action: string;
  Data: ITicketCreateChangeStatus;
};
type ticketCreateChangeProfile = {
  SubjectIdentifier: "create-ticket-change-profile";
  Action: string;
  Data: ITickerCreateChangeProfile;
};

type updateTicket = {
  SubjectIdentifier: "updatecsspv";
  Action: string;
  Data: any;
};
type updateTicketCSStaff = {
  SubjectIdentifier: "updatecsstaff";
  Action: string;
  Data: any;
};
type updateTicketFinStaff = {
  SubjectIdentifier: "updatefinstaff";
  Action: string;
  Data: any;
};
type updateTicketFinSpv = {
  SubjectIdentifier: "updatefinspv";
  Action: string;
  Data: any;
};
type uploadPhoto = {
  SubjectIdentifier: "ektp";
  Action: "upload-photo";
  Data: {
    Photo: string;
    PhotoType: string;
  };
};
type ceksaldo = {
  SubjectIdentifier: "ceksaldo";
  Action: string;
  Data: any;
};

export interface IMiddlewareDataOut {
  TicketId: string;
  Subject: "adjudicator" | "digitalid" | "ticketing" | "ticket";
  SubjectType: "adjudicator" | "enrollment" | "ticketing" | "ticket" | "paspor";
  SubjectIdentifier:
    | "adjupdate"
    | "listadmin"
    | "listspv"
    | "liststaff"
    | "detailbyid"
    | "web-admin"
    | "listcsstaff"
    | "listcsspv"
    | "listfinstaff"
    | "listfinspv"
    | "detailbyticketid"
    | "ektp"
    | "updatecsspv"
    | "updatefinstaff"
    | "updatecsstaff"
    | "updatefinspv"
    | "create-ticket-closed-account"
    | "create-ticket-refund"
    | "create-ticket-change-status"
    | "create-ticket-change-profile"
    | "ceksaldo";
  Data?: any;
}

export interface IMiddleResponse {
  TicketId: string;
  Subject: string;
  SubjectType: string;
  SubjectIdentifier: string;
  Action: string;
  ResourceType: string;
  Resource: string;
  ResourceId: string;
  Result: any;
  Data: any;
  Status: string;
  Message: string;
  StatusCode: number;
}

export type headersMiddelware = {
  "Idempotency-Key": string;
};

interface GetMiddlewareINAction {
  type: MiddlewareTypes.MIDDLEWARE_IN_SUCCESS;
  payload: {
    data: IMiddleResponse;
  };
}
interface GetMiddlewareOUTAction {
  type: MiddlewareTypes.MIDDLEWARE_OUT_SUCCESS;
  payload: {
    data: IMiddleResponse;
  };
}

interface SetLoadingMiddlewareOUTAction {
  type: MiddlewareTypes.MIDDLEWARE_OUT_PENDING;
  payload?: any;
}
interface SetLoadingMiddlewareINAction {
  type: MiddlewareTypes.MIDDLEWARE_IN_PENDING;
  payload?: any;
}

interface SetErrorMiddlewareINAction {
  type: MiddlewareTypes.MIDDLEWARE_IN_ERROR;
  payload: {
    error: AxiosError;
  };
}
interface SetErrorMiddlewareOUTAction {
  type: MiddlewareTypes.MIDDLEWARE_OUT_ERROR;
  payload: {
    error: AxiosError;
  };
}

export interface IMiddlewareState {
  data: any;
  isLoading: boolean;
  error: AxiosError;
}

export type MiddlewareAction =
  | GetMiddlewareINAction
  | GetMiddlewareOUTAction
  | SetLoadingMiddlewareINAction
  | SetLoadingMiddlewareOUTAction
  | SetErrorMiddlewareINAction
  | SetErrorMiddlewareOUTAction;
