import { AxiosError } from "axios";
import { IMiddleResponse } from "./middlewareTypes";

export enum RefundType {
  REFUND_PENDING = "REFUND_PENDING",
  REFUND_SUCCESS = "REFUND_SUCCESS",
  REFUND_ERROR = "REFUND_ERROR",
}

export interface IParamRefund {
  size?: number;
  current?: number;
  status?: string;
  startDate?: string;
  endDate?: string;
  Id?: number;
  Destination?: string;
  Product?: any;
  Payment?: string;
  orderType?: any;
  keyword?: string;
}

export interface IParamRefundByReceipt {
  receipt?: string;
}

export interface IRefundDataMaster {
  trxId: number;
  trxClientId: number;
  trxMemberId: number;
  trxType: number;
  trxOrderType: string;
  trxPaymentType: string;
  trxDate: string;
  trxReceipt: string;
  trxUser: string;
  trxStatus: string;
  trxPhone: string;
  trxPayload: string;
  pGroup: string;
  pName: string;
  mArea: string;
}

export interface IRefundResponse {
  code: number;
  status: string;
  message: string;
  data: any;
  paginate: {
    page: number;
    total_page: number;
    total_record: number;
    limit: number;
  };
}

export interface IRefundResponseMaster {
  Code: number;
  Status: string;
  Message: string;
  Data: IRefundDataMaster;
  Paginate: {
    CurrentPage: number;
    NextPage: number;
    PrevPage: number;
    TotalPage: number;
    TotalRow: number;
  };
}

export interface IMiddlewareBodyRefundIn {
  ClientId: number;
  Subject: "ewallet" | "refund";
  SubjectType: "web-transaction" | "refund";
  SubjectIdentifier: "refund" | "add";
  Action: "list" | "create" | "get-transaction-by-receipt";
  ResourceType: "data";
  Resource: "data";
  ResourceId: "data";
  UserId: string;
  Date: string;
  ScopePlatform: "private";
  Platform: "qoin-apps";
  Data: IParamRefund | IParamCreate | IParamRefundByReceipt;
}

export interface IParamCreate {
  trxReceipt: string;
}

export interface IMiddlewareBodyRefundOut {
  TicketId: string;
  Subject: "ewallet" | "refund";
  SubjectType: "web-transaction" | "refund";
  SubjectIdentifier: "refund" | "add" | "view";
}

export interface IMiddlewareResponseRefundOut {
  ClientId: number;
  Subject: "refund";
  SubjectType: "refund";
  SubjectIdentifier: "list";
  Action: "get";
  ResourceType: null;
  Resource: null;
  ResourceId: null;
  UserId: string;
  Date: string;
  Result: IRefundResponseMaster | any;
  Status?: string;
  StatusCode?: number;
}

interface GetRefundAction {
  type: RefundType.REFUND_SUCCESS;
  payload: {
    data: IMiddlewareResponseRefundOut | IMiddleResponse | null;
  };
}

interface SetLoadingRefundAction {
  type: RefundType.REFUND_PENDING;
  payload?: {
    data: any;
  };
}

interface SetErrorRefundAction {
  type: RefundType.REFUND_ERROR;
  payload: {
    error: AxiosError;
  };
}

export interface IRefundState {
  data: IMiddlewareResponseRefundOut | IMiddleResponse;
  isLoading: boolean;
  error: AxiosError;
  dataDetail: any;
  dataWithReceipt: any;
  isLoadingDetail: boolean;
  dataExport: any;
  isLoadingExport: boolean;
  isLoadingDDl: boolean;
  isLoadingCreate: boolean;
}

export type RefundAction =
  | GetRefundAction
  | SetLoadingRefundAction
  | SetErrorRefundAction;
