import { AxiosError } from "axios";

export enum PermType {
  PERM_PENDING = "PERM_PENDING",
  PERM_SUCCESS = "PERM_SUCCESS",
  PERM_ERROR = "PERM_ERROR",
}

export interface IPermData {
  Id?: number | string;
  RoleId?: number | string;
  PermissionKey?: any;
}

export interface IPermResponse {
  code: number;
  message: string;
  status: string;
  Paginate?: {
    CurrentPage: number;
    TotalPage: number;
    TotalRow: number;
    PerPage: number;
  };
  data: IPermData[] & IPermData;
}

interface GetPermAction {
  type: PermType.PERM_SUCCESS;
  payload: {
    data: IPermResponse;
  };
}

interface SetLoadingPermAction {
  type: PermType.PERM_PENDING;
  payload?: any;
}

interface SetErrorPermAction {
  type: PermType.PERM_ERROR;
  payload: {
    error: AxiosError;
  };
}

export interface IParamPerm {
  limit?: number;
  page?: number;
  sort?: string;
  totalPages?: number;
}

export interface IPermState {
  data: IPermData[] & IPermData;
  isLoading: boolean;
  error: AxiosError;
}

export type PermAction =
  | GetPermAction
  | SetLoadingPermAction
  | SetErrorPermAction;
