import { AxiosError } from "axios";

export enum AdjType {
  ADJ_PENDING = "ADJ_PENDING",
  ADJ_SUCCESS = "ADJ_SUCCESS",
  ADJ_ERROR = "ADJ_ERROR",
}

export interface IAdjData {
  OldPassword?: string;
  NewPassword?: string;
  PasswordConfirmation?: string;
  Password?: string;
  Email?: string;
  Key?: string;
  Platformkey?: string;
  Token?: string;
}

export interface IParamAdj {
  pageSize: number;
  page: number;
  search?: string;
  totalPages?: number;
  searchSelect?: string;
}

export interface IAdjResponse {
  code: number;
  status: string | number;
  message: string;
  data: any;
  paginate: {
    page: number;
    total_page: number;
    total_record: number;
    limit: number;
  };
}

interface GetAdjAction {
  type: AdjType.ADJ_SUCCESS;
  payload: {
    data: IAdjResponse;
  };
}

interface SetLoadingAdjAction {
  type: AdjType.ADJ_PENDING;
  payload?: any;
}

interface SetErrorAdjAction {
  type: AdjType.ADJ_ERROR;
  payload: {
    error: AxiosError;
  };
}

export interface IAdjState {
  data: any;
  isLoading: boolean;
  error: AxiosError;
}

export type AdjAction = GetAdjAction | SetLoadingAdjAction | SetErrorAdjAction;
