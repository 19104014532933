import { AxiosError } from "axios";
import { ThunkAction } from "redux-thunk";
import { RootState } from "..";
import provider, { IProvider } from "../../provider";
import { IError } from "../types/errorTypes";
import MethodProviders from "../../provider/methods";
import {
  AuthAction,
  AuthType,
  IAuthData,
  IAuthResponse,
} from "../types/authTypes";
import endpoints from "../../provider/endpoints";
import { environment } from "../../provider/environments";
import { errorGlobal } from "./errorAction";

export const authPending = (): AuthAction => ({
  type: AuthType.AUTH_PENDING,
});

export const authSuccess = (data: IAuthResponse): AuthAction => ({
  type: AuthType.AUTH_SUCCESS,
  payload: {
    data,
  },
});

export const authError = (error: AxiosError): AuthAction => ({
  type: AuthType.AUTH_ERROR,
  payload: {
    error,
  },
});

export const initialization = (
  body: IAuthData,
  callback?: (data: IAuthResponse) => void
): ThunkAction<void, RootState, null, AuthAction | IError> => {
  return async (dispatch) => {
    dispatch(authPending());

    try {
      const objProvider: IProvider = {
        method: MethodProviders.POST,
        data: body,
        path: endpoints.accessV2.initialization,
        baseURL: environment.sass_url,
      };

      const { data } = await provider(objProvider);
      dispatch(authSuccess(data));
      callback(data);
    } catch (error) {
      dispatch(errorGlobal(error));
      callback(error?.response?.data);
      dispatch(authError(error));
    }
  };
};

export const login = (
  body: any,
  headers?: any,
  callback?: (data: IAuthResponse) => void
): ThunkAction<void, RootState, null, AuthAction | IError> => {
  return async (dispatch) => {
    dispatch(authPending());

    try {
      const objProvider: IProvider = {
        method: MethodProviders.POST,
        data: body,
        path: endpoints.accessV2.login,
        // path: endpoints?.login,
        baseURL: environment.sass_url,
        // baseURL: environment?.base_url,
        headers: headers,
      };

      const { data } = await provider(objProvider);
      dispatch(authSuccess(data));
      callback(data);
    } catch (error) {
      dispatch(errorGlobal(error));
      callback(error?.response?.data);
      dispatch(authError(error));
    }
  };
};

export const forgotPassword = (
  body: IAuthData,
  callback?: (data: IAuthResponse) => void
): ThunkAction<void, RootState, null, AuthAction | IError> => {
  return async (dispatch) => {
    dispatch(authPending());

    try {
      const objProvider: IProvider = {
        method: MethodProviders.POST,
        data: body,
        path: endpoints.accessV2.forgotPassword,
        baseURL: environment.sass_url,
      };

      const { data } = await provider(objProvider);
      dispatch(authSuccess(data));
      callback(data);
    } catch (error) {
      dispatch(errorGlobal(error));
      callback(error?.response?.data);
      dispatch(authError(error));
    }
  };
};

export const changePassword = (
  body: IAuthData,
  callback?: (data: IAuthResponse) => void
): ThunkAction<void, RootState, null, AuthAction | IError> => {
  return async (dispatch) => {
    dispatch(authPending());

    try {
      const objProvider: IProvider = {
        method: MethodProviders.POST,
        data: body,
        baseURL: environment?.sass_url,
        path: endpoints.accessV2.changePassword,
      };

      const { data } = await provider(objProvider);

      callback(data);
      dispatch(authSuccess(data));
    } catch (error) {
      dispatch(errorGlobal(error));
      callback(error?.response?.data);
      dispatch(authError(error));
    }
  };
};

export const refreshToken = (
  body: any,
  callback?: (data: any) => void
): ThunkAction<void, RootState, null, AuthAction | IError> => {
  return async (dispatch) => {
    dispatch(authPending());

    try {
      const objProvider: IProvider = {
        method: MethodProviders.POST,
        data: body,
        baseURL: environment?.base_url,
        path: endpoints.refreshToken,
      };

      const { data } = await provider(objProvider);

      callback(data);
      dispatch(authSuccess(data));
    } catch (error) {
      dispatch(errorGlobal(error));
      callback(error?.response?.data);
      dispatch(authError(error));
    }
  };
};
