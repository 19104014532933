import React, { Suspense, lazy, useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import PublicRoute from "./componentv2/PublicRoute";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import "./App.scss";
import { PopupKeycloak } from "./views/Pages2";
import Loading from "./utils/Loading";

const Layout = lazy(() => import("./containers/Layout/Component"));
const Login = lazy(() => import("./views/Pages2/LoginOld"));
const LoginKeyCloak = lazy(() => import("./views/Pages2/LoginKeycloak"));
const ForgotPassword = lazy(() => import("./views/Pages2/ForgotPassword"));
const ViewComponents = lazy(() => import("./views/Pages2/ViewComponent"));

const App = () => {
  return (
    <>
      <ToastContainer
        position="top-center"
        newestOnTop
        autoClose={3000}
        limit={3}
        className="text-capitalize"
      />
      <BrowserRouter>
        <Suspense fallback={Loading()}>
          <Switch>
            {/* <PublicRoute
              exact
              name="Login Page"
              path="/login"
              component={Login}
            /> */}
            <PublicRoute
              exact
              name="Login Page"
              path="/login-keycloak"
              component={LoginKeyCloak}
            />
            <PublicRoute
              exact
              name="Keycloak"
              path="/keycloak"
              component={PopupKeycloak}
            />
            <PublicRoute
              exact
              name="Forgot Password Page"
              path="/forgot-password"
              component={ForgotPassword}
            />
            <PublicRoute
              exact
              name="Component Page"
              path="/view-compo"
              component={ViewComponents}
            />
            <Route
              path="/"
              name="Home"
              render={(props) => <Layout {...props} />}
            />
          </Switch>
        </Suspense>
      </BrowserRouter>
    </>
  );
};

export default App;
