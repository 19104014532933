import { updateObject } from "../../utils/utility";
import { ErrorType, IErrorState } from "../types/errorTypes";

const initialState = {
  message: "",
  code: "",
  status: "",
};

export default (state: IErrorState = initialState, { type, payload }) => {
  switch (type) {
    case ErrorType.ERROR_SUCCESS:
      return updateObject(state, {
        message:
          payload?.error || payload?.error?.message || payload?.error?.Message,
        code: payload?.error?.code || payload?.error?.Code || "",
        status: payload?.error?.status || "",
      });

    default:
      return state;
  }
};
