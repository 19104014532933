import React, { useEffect, useState } from "react";
import { IDropdown } from "./interface";
import "./styles.scss";

const Component: React.FC<IDropdown> = ({
  data,
  filterText,
  setValue,
  className,
  disable
}) => {
  const [options, setOptions] = useState<any>([]);

  useEffect(() => {
    const dataOption = data?.filter(
      (dataItem: { name: string; value: string | number }) =>
        !filterText ||
        dataItem?.name?.toLowerCase().includes(filterText?.toLowerCase())
    );

    setOptions(
      dataOption.length < 1 ? [{ name: "No options", value: "" }] : dataOption
    );
  }, [data, filterText]);

  return (
    <div className={`dropdown-comp ${className}`} >
      {options?.map(
        (
          dataOptions: {
            value: string | number;
            name: string | number;
          },
          idx: React.Key
        ) => {
          return <>
            <button
              className={`py-2 w-100 text-left`}
              key={idx}
              onClick={(evt) => {
                evt?.preventDefault();
                setValue(dataOptions);
              }}
              disabled={dataOptions?.name === "" || disable}
              value={dataOptions?.name}
            >
              {dataOptions?.name}
            </button>
          </>
        }
      )}
    </div>
  );
};

export default Component;
