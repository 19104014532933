import { updateObject } from "../../utils/utility";
import {
  ProfileAction,
  ProfileType,
  IProfileState,
} from "../types/profileTypes";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

export default (
  state: IProfileState = initialState,
  { type, payload }: ProfileAction
) => {
  switch (type) {
    case ProfileType.PROFILE_PENDING:
      return updateObject(state, { ...payload, error: null, isLoading: true });
    case ProfileType.PROFILE_SUCCESS:
      return updateObject(state, { ...payload, isLoading: false });
    case ProfileType.PROFILE_ERROR:
      return updateObject(state, { ...payload, isLoading: false });

    default:
      return state;
  }
};
